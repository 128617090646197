import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { TPAGalleryControllerActions } from '../TPAGallery.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): TPAGalleryControllerActions => ({
  onCurrentItemChanged: event => {
    updateProps({
      currentIndex: event.itemIndex,
    });
  },
  onUnMount: () => {
    updateProps({ componentReady: false });
  },
});

export default mapActionsToProps;
