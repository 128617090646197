import StripShowcaseComponent from '@wix/thunderbolt-elements/src/components/StripShowcase/viewer/StripShowcase';
import StripShowcaseController from '@wix/thunderbolt-elements/src/components/StripShowcase/viewer/StripShowcase.controller';


const StripShowcase = {
  component: StripShowcaseComponent,
  controller: StripShowcaseController
};


export const components = {
  ['StripShowcase']: StripShowcase
};


// temporary export
export const version = "1.0.0"
